<template>
    <div class="user-list">
        <!-- 筛选项 -->
        <el-form :inline="true" size="mini" :model="form" ref="filterFormRef" label-width="100px">
            <el-form-item label="角色名称:" prop="roleName">
                <el-input style="width: 200px;" v-model="form.roleName" placeholder="请输入角色名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="角色状态:" prop="status">
                <el-select v-model="form.status" placeholder="请选择角色状态" clearable>
                    <el-option label="有效" value="1"></el-option>
                    <el-option label="无效" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="margin-left: 20px;">
                <el-button type="primary" icon="el-icon-search" @click="searchList">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetFilterForm">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- 筛选项 -->
        <div class="operate">
            <el-button type="primary" size="mini" icon="el-icon-plus" @click="meuVisibleHnadle">新增角色</el-button>
        </div>
        <!-- 表格内容 -->
        <el-table :data="tableData" border style="width: 100%" v-loading="roleTableLoading">
            <el-table-column prop="id" label="角色ID" width="80"></el-table-column>
            <el-table-column prop="roleName" label="角色名称"></el-table-column>
            <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                    <span>{{ scope.row.status == 0 ? "无效" : "有效" }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="createAt" label="创建时间"></el-table-column>
            <el-table-column prop="updateAt" label="更新时间"></el-table-column>
            <el-table-column fixed="right" label="操作" width="220">
                <template slot-scope="scope">
                    <el-row :gutter="10" >
                        <template v-if="!(scope.row.isDefault===1&&scope.row.roleName==='管理员')&&!(scope.row.isDefault===1&&scope.row.roleName==='普通用户')">
                            <el-col :span="12" style="margin: 6px 0px;">
                                <el-button type="primary" size="mini" @click="configurationHandle(scope.row)">
                                    配置规则
                                </el-button>
                            </el-col>
                            <el-col :span="12" style="margin: 6px 0px;">
                                <el-button  size="mini" @click="editHandle(scope.row)">
                                    编辑信息
                                </el-button>
                            </el-col>
                        </template>
                        <template v-if="!(scope.row.isDefault===1&&scope.row.roleName==='普通用户')">
                            <el-col :span="12" style="margin: 6px 0px;"> 
                                <el-button type="primary" size="mini" @click="allocationMenuHandle(scope.row)">
                                    分配菜单
                                </el-button>
                            </el-col>
                            <el-col :span="12" style="margin: 6px 0px;">
                                    <el-button  size="mini" @click="copyHandle(scope.row)">
                                        复制角色
                                    </el-button>
                            </el-col>
                            <el-col :span="12" style="margin: 6px 0px;">
                                <el-button size="mini" @click="recordsHandle(scope.row)">
                                    操作记录
                                </el-button>
                            </el-col>
                        </template>
                    </el-row>
                </template>
            </el-table-column>
        </el-table>
        <!-- 表格内容 -->
        <!-- 分页 -->
        <el-pagination
                small
                @current-change="handleCurrentChange"
                :page-size="limit"
                layout="total, prev, pager, next, jumper"
                :total="total"
                :current-page="currentPage">
        </el-pagination>
        <!-- 分页 -->
         <el-dialog
                :title="`${typeList[type]}角色`"
                :visible.sync="meuVisible"
                width="30%"
                @close="meuVisibleClose"
        >
            <el-form
                    size="mini"
                    :model="roleForm"
                    ref="refMenu"
                    :rules="rules"
                    width="50%"
                    label-width="100px">
                <el-form-item label="角色名称:"   prop="roleName">
                    <el-input v-model="roleForm.roleName" ></el-input>
                </el-form-item>
                <el-form-item label="角色状态:"   prop="status">
                    <el-select v-model="roleForm.status" clearable>
                        <el-option label="有效" :value="1"></el-option>
                        <el-option label="无效" :value="0"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button size="mini" @click="dislogCancelHnadle">取 消</el-button>
              <el-button size="mini" type="primary" @click="submit">提 交</el-button>
            </div>
        </el-dialog>

        <el-dialog
                title="操作记录"
                :visible.sync="recordsVisible"
                width="60%"
                @close="recordsVisibleClose">
            <el-table border :data="recordsData" height="400" style="width: 100%;" v-loanding="rlogTableLoading">
                 <el-table-column prop="createBy"  label="操作人" />
                 <el-table-column prop="menuName"  label="操作行为" />
                 <el-table-column prop="operationDesc"  label="操作备注" />
                 <el-table-column prop="createAt"  label="操作时间"/>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { 
    getQuerySysRolePageList,
    getQuerySysRoleOperateLog,
    addSysRole,
    editSysRole,
    copySysRole
} from "@/api/role.js";
export default {
    props: {},
    data() {
        return {
            typeList:{
                'add':'新增',
                'edit':'编辑',
                'copy':'复制'
            },
            recordsData:[],
            type:'',
            roleForm:{ // 新增
                id:'',
                roleName:'',
                status:null,
            },
            meuVisible:false,
            recordsVisible:false,
            tableData:[
                {
                    id:1
                }
            ],
            roleTableLoading: false,
            rlogTableLoading: false,
            form: { // 搜索条件
                roleName: "",
                status: null
            },
            total: 0,
            currentPage: 1,
            limit: 10,
            rules:{
                roleName: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                status: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ]
            }
        };
    },
    created() {
        this.getList()
    },
    methods: {
        async copyRole(row){ // 复制角色
            try {
                let params={
                    roleName:this.roleForm.roleName,
                    status:this.roleForm.status,
                    roleId:this.roleForm.id,
                    // menuId:3
                    menuCode:'copy_role'
                }
                let res=await copySysRole(params)
                
                if(res.code===0){
                        this.$message({
                            type: 'success',
                            message: '复制成功!'
                        });
                        this.getList()
                        this.meuVisible=false
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });
                }
                
                
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: `${error||'操作失败!'}`
                });
            }
        },
        async editRole(row){ // 编辑角色
            try {
                let params={
                    roleName:this.roleForm.roleName,
                    status:this.roleForm.status,
                    roleId:this.roleForm.id,
                    // menuId:2
                    menuCode:'edit_role'
                }
                let res=await editSysRole(params)
                
               if(res.code===0){
                        this.$message({
                            type: 'success',
                            message: '编辑成功!'
                        });
                        this.getList()
                        this.meuVisible=false
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: `${error||'操作失败!'}`
                });
            }
        },
        async addRole(){ // 新增角色
            try {
                let params={
                    roleName:this.roleForm.roleName,
                    status:this.roleForm.status,
                    // menuId:1
                    menuCode:'add_role'
                }
                let res=await addSysRole(params)
                if(res.code===0){
                    this.meuVisible=false
                    this.$message({
                        type: 'success',
                        message: '新增成功!'
                    });
                    this.getList()
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: `${error||'操作失败!'}`
                });
            }
        },
       async getList(){ // 列表
            try {
                let params={
                    pageNo:this.currentPage,
                    pageSize:this.limit,
                    ...this.form
                }
                this.roleTableLoading=true
                this.tableData=[]
                let res=await getQuerySysRolePageList(params)
                this.tableData=res.data.records
                this.total=res.data.total
                this.currentPage=res.data.current
            } catch (error) {
                console.log('error',error);
            } finally{
                this.roleTableLoading=false
            }
        },
        async getLogList(row){ // 操作记录
            try {
                this.rlogTableLoading=true
                this.recordsData=[]
                let res= await getQuerySysRoleOperateLog({roleId:row.id})
                if(res.code===0){
                    this.recordsData=res.data
                }
            } catch (error) {
                console.log('error',error);
            } finally{
                this.rlogTableLoading=false
            }
        },
        configurationHandle(row){ // 配置规则
            this.$router.push({ 
                path: `/role/configuration/${row.id}` ,
                query: {
                    ...row
                }
             });
        },
        submit(){ //-提交
            this.$refs.refMenu.validate(async (valid) => {
                if(valid){
                    switch(this.type){
                        case 'add':
                            this.addRole()
                            break;
                        case 'edit':
                            this.editRole()
                            break;
                        case 'copy':
                            this.copyRole()
                            break;
                    }
                }else{
                    return false
                }
            })
        },
        recordsVisibleClose(){
            this.recordsVisible=false
        },
        recordsHandle(row){
            this.recordsVisible=true
            this.getLogList(row)
        },
        meuVisibleClose(m){
            this.meuVisible=false
            this.type==='add'&&this.clearForm()
            this.$refs.refMenu.clearValidate();
        },
        clearForm(){
            Object.keys(this.roleForm).forEach(item=>{
                this.roleForm[item]=''
            })
        },
        dislogCancelHnadle(){ // 弹框的取消
            this.meuVisible = false
            this.type==='add'&&this.clearForm()
            this.$refs.refMenu.clearValidate();
        },
        // 配置菜单
        meuVisibleHnadle(){// 新增
            this.meuVisible=true
            this.type='add'
            this.clearForm()
        },
        // 编辑用户
        async editHandle(row) {
            this.type='edit'
            this.meuVisible=true
            this.roleForm.id=row.id
            this.roleForm.roleName=row.roleName
            this.roleForm.status=row.status
        },
        copyHandle(row){
            this.type='copy'
            this.meuVisible=true
            this.roleForm.id=row.id
            this.roleForm.roleName=row.roleName
            this.roleForm.status=row.status
        },
        allocationMenuHandle(row){ 
            let id=1
            // this.$router.push({ path: `/role/detail/${id}` });//
            this.$router.push({ 
                path: `/role/detail/${id}` ,
                query: {
                    ...row
                }
             });//detail
        },
         // 搜索重置
         resetFilterForm() {
            this.$refs.filterFormRef.resetFields();
            this.currentPage=1
            this.getList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
        searchList(){
            this.currentPage=1
            this.getList()
        }
    },
};
</script>

<style scoped lang="scss">
.user-list {
    //overflow-y: scroll;
    .form {
        display: block;
    }

    .operate {
        margin: 25px 0;
        float: right;
    }

    .email_verify_btn {
        background: $color-theme-normal !important;
        color: #ffffff !important;
        border: 2px solid $color-theme-normal !important;
        border-radius: 0 !important;
        font-size: $font-small !important;
    }
    .dialog-footer{
        display: flex;
        justify-content: center;
    }
}
</style>